import { MantineProvider } from "@mantine/core"
import React from "react"
import { HashRouter, Route, Routes } from "react-router-dom"
import CareerPage from "./components/career-page/career-page"
import { RoutesOptions } from "./constants"
import HomePage from "./components/home-page/home-page"
import "./App.css"
import ServicesPage from "./components/services-page/services-page"
import BlogPage from "./components/blog/blog-page"
import Post1 from "./components/posts/post-1"
import Post2 from "./components/posts/post-2"
import Post3 from "./components/posts/post-3"
import Post4 from "./components/posts/post-4"
import Post5 from "./components/posts/post-5"
import Post6 from "./components/posts/post-6"
import Post7 from "./components/posts/post-7"
import Post8 from "./components/posts/post-8"
import Post9 from "./components/posts/post-9"
import Post10 from "./components/posts/post-10"
import Post11 from "./components/posts/post-11"
function App() {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        fontSizes: {
          xs: "0.75em",
          sm: "0.875em",
          md: "1em",
          lg: "1.125em",
          xl: "1.25em",
        },
        colors: {
          golden: [
            "#fdf6e6",
            "#172a33",
            "#e4d3b7",
            "#d3bb90",
            "#c6a670",
            "#bd995b",
            "#b9934f",
            "#BA9451",
            "#98783e",
            "#7e6028",
          ],
        },
      }}
    >
      <div className="App">
        <HashRouter>
          <Routes>
            <Route path={RoutesOptions.ROOT} element={<HomePage />} />
            <Route path={RoutesOptions.CAREER} element={<CareerPage />} />
            <Route path={RoutesOptions.SERVICES} element={<ServicesPage />} />
            <Route path={RoutesOptions.BLOG} element={<BlogPage />} />
            <Route path={RoutesOptions.POST_1} element={<Post1 />} />
            <Route path={RoutesOptions.POST_2} element={<Post2 />} />
            <Route path={RoutesOptions.POST_3} element={<Post3 />} />
            <Route path={RoutesOptions.POST_4} element={<Post4 />} />
            <Route path={RoutesOptions.POST_5} element={<Post5 />} />
            <Route path={RoutesOptions.POST_6} element={<Post6 />} />
            <Route path={RoutesOptions.POST_7} element={<Post7 />} />
            <Route path={RoutesOptions.POST_8} element={<Post8 />} />
            <Route path={RoutesOptions.POST_9} element={<Post9 />} />
            <Route path={RoutesOptions.POST_10} element={<Post10 />} />
            <Route path={RoutesOptions.POST_11} element={<Post11 />} />
          </Routes>
        </HashRouter>
      </div>
    </MantineProvider>
  )
}

export default App
