import React, { useEffect } from "react"
import { MiddleContainer } from "../../assets/middle-container"
import { FooterPage } from "../footer/footer"
import { HeaderPage } from "../header/header"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { Button, Flex, Stack, Text, Image } from "@mantine/core"
import { maxWidthTablet, RoutesOptions } from "../../constants"
import { useMediaQuery } from "@mantine/hooks"
import { useNavigate } from "react-router-dom"

export default function Post11() {
  const language = localStorage.getItem("i18nextLng")
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (language) i18next.changeLanguage(language)
  }, [language])
  const postTextRo = (
    <Stack my="lg" spacing="xs">
      <Text className="text-post">
        Una din cauzele recente care m-a marcat profund a fost cauza unei
        studente doctorand, cetățean străin, venită în România pentru studii
        doctorale la o prestigioasă universitate din orașul nostru -{" "}
        <b>Universitatea Politehnica Timișoara</b>.
      </Text>
      <Text className="text-post">
        După primul an de studii doctorale, încheiat foarte bine de studenta în
        discuție, aceasta trebuia să își prelungească șederea în România, astfel
        că s-a prezentat la Inspectoratul General pentru Imigrări – Serviciul
        pentru Imigrări Timiș pentru prelungirea permișului său de ședere.
      </Text>
      <Text className="text-post">
        Studenta noastră s-a trezit însă în mijlocul unui adevărat coșmar pentru
        că în loc să i se prelungească șederea legală în România pentru cel
        de-al doilea an de studii doctorale așa cum se aștepta, i-a fost
        comunicată o decizie de returnare de pe teritoriul României în țara sa
        natală, dar și o interdicție de intrare pe teritoriul statelor din
        Spațiul Schengen pentru o perioadă de 5 ani, fiind bănuită de o ședere
        ilegală a acesteia pe teritoriul României.
      </Text>
      <Text className="text-post">
        O prietenă tară dragă și un om cu suflet mare,{" "}
        <b>
          Emilia Szuszmann - International Students Recruitment and Marketing
          Officer
        </b>{" "}
        în cadrul Universității Politehnica Timișoara, m-a întrebat dacă nu aș
        putea prelua eu cazul acestei studente pentru că o cunoaște personal,
        este o studentă eminentă și foarte serioasă și bănuiește că, la mijloc,
        nu poate fi vorba decât fie de o nedreptate, fie o greșeală a
        autorităților implicate, iar interdiția de 5 ani pe teritoriul Schengen
        o va afecta iremediabil din punct de vedere educațional și profesional.
      </Text>
      <Text className="text-post">
        Așa se face că am preluat acest caz, din care am avut foarte multe de
        învățat, mai ales la nivel uman. În primul rând, am fost foarte plăcut
        impresionată de clienta mea - de bunul simț al acesteia atât în relația
        client - avocat, cât și de modul în care aceasta s-a prezentat și s-a
        adresat în fața instanței de judecată, cu un respect deosebit pentru
        completul de judecată.
      </Text>

      <Text className="text-post">
        Cel mai mult m-a impresionat însă faptul că atunci reclamanta a spus
        adevărul cu toată ființa sa în sala de judecată, toți participanții la
        actul de justiție au început să conlucreze. Nu numai instanța de
        judecată, care este chemată de lege să afle și să stabilească adevărul
        în orice cauză, și-a exercitat foarte activ rolul, dar chiar și
        <b>
          {" "}
          ADVERSARUL DIN PROCES ȘI-A SCHIMBAT ATITUDINEA ȘI MODUL DE ACȚIUNE ÎN
          TIMPUL PROCESULUI și CHIAR POZIȚIA PROCESUALĂ, ATUNCI CÂND S-A AFLAT
          FAȚĂ ÎN FAȚĂ CU ADEVĂRUL SPUS DE PARTEA POTRIVNICĂ.
        </b>
      </Text>

      <Text className="text-post">
        Astfel, după ce prin întâmpinare, pârâtul Inspectoratul General pentru
        Imigrări – Serviciul pentru Imigrări Timiș a solicitat respingerea
        contestației depuse de reclamantă, justificându-și simplu măsurile
        dispuse prin faptul că în sistemul de semnalare SINS (Sistemul
        Informatic Naţional de Semnalări) reclamanta figurează cu alertă
        introdusă de Ungaria, ulterior în cursul procesului, după ce și-a dat
        seama că reclamanta spune adevărul, nu s-a mai opus probelor solicitate
        de aceasta, ba chiar a făcut toate verificările administrative necesare
        pentru a se stabili realitatea faptică în legătură cu persoana
        reclamantă. Apoi, în timpul procesului, și-a revocat propria decizia de
        returnare, solicitând respingerea contestației reclamantei ca rămasă
        fără obiect.
      </Text>
      <Text className="text-post">
        Revocarea deciziei de returnare de către Inspectoratul General pentru
        Imigrări – Serviciul pentru Imigrări Timiș a venit pe seama faptului că
        autoritățile maghiare, cele care au introdus alerta în Sistem, au
        revocat la rândul lor, decizia de impunere a interdicţiei de intrare şi
        şedere și de ștergere a alertei din sistemul de semnalare SINS (Sistemul
        Informatic Naţional de Semnalări).
      </Text>
      <Text className="text-post">
        Așadar, probele administrate în cauză (pentru care, desigur, că noi
        ne-am străduit cel mai mult să le obținem și să le prezentăm în fața
        instanței de judecată) au demonstrat faptul că studenta noastră nu a
        fost de fapt, persoana despre care se bănuia că a fost prinsă când
        încerca să evite controlul de frontieră Ungaria-România şi că ar fi fost
        predată către autorităţile române la frontieră.
      </Text>
      <Text className="text-post">
        Iar hotărârea <b>Curtii de Apel Timișoara</b> a fost bineînțeles una
        favorabilă clientei mele reclamantă în cauză, fiind important de
        subliniat faptul că instanța nu a respins contestația ca rămasă fără
        obiect, așa cum a cerut pârâtul, ci a anulat decizia de returnare așa
        cum am solicitat noi, reținând următoarele considerențele:
      </Text>
      <Text
        className="text-post"
        style={{ fontStyle: "italic", fontWeight: 700 }}
      >
        “…odată comunicată decizia de returnare destinatarului acesteia,
        autoritatea publică emitentă nu mai poate proceda la revocarea actului
        administrativ, care a intrat în circuitul civil, a produs efecte
        juridice, unul dintre acestea fiind chiar suspendarea procedurii de
        emitere a unui nou permis de şedere în scop de studii, solicitat de
        reclamantă la data de..... Respingerea ca rămasă fără obiect ar
        presupune s-a emis un act juridic de aceeaşi valoare cu actul atacat (în
        speţă decizia de returnare) prin care acesta din urmă a fost lăsat fără
        efecte juridice. Chiar dacă decizia de returnare, care are ca obiect
        obligarea reclamantei de a părăsi teritoriul României şi interdicţia de
        a intra 5 ani pe teritoriul statelor membre ale Uniunii Europene,
        Spaţiului Economic European şi Confederaţiei Elveţiene, nu se mai
        execută şi cererea de eliberare a permisului de şedere a fost repusă pe
        rol, cererea de chemare în judecată are un obiect: actul juridic
        continuă să existe până la înlăturarea sa din circuitul civil. Prin
        urmare, chiar dacă pârâtul nu intenţionează să pună în executare actul,
        apreciindu-l ca lipsit de efecte juridice, scoaterea din circuitul civil
        se produce prin hotărârea instanţei. Astfel cum rezultă din expunerile
        din precedent, decizia de returnare a fost emisă în baza unei semnalări
        din sistemul de evidenţă Schengen care ulterior a fost revocată de către
        autoritatea care a dispus-o. În acest context, decizia de returnare nu
        mai are suport în realitatea juridică, impunându-se anularea acesteia,
        în conformitate cu art. 18 alin. (4) lit. a) din Legea Soluţia priveşte
        atât măsura returnării, cât şi cea a interdicţiei de intrare pe o
        perioadă de 5 ani, având în vedere că ambele au fost dispuse prin
        acelaşi act administrativ.”
      </Text>
      <Text className="text-post">
        Ce am învățat din această cauză este că{" "}
        <b>
          ORICINE POATE GREȘI (chiar și autorități de la cel mai înalt nivel)
        </b>
        , dar și că <b>ADEVĂRUL ÎNVINGE ORICE GREȘEALĂ.</b> Astfel, nu pot decât
        să <b>GREȘEALĂ</b>. Astfel, nu pot decât să îndemn pe oricine ca atunci
        când are dreptate, să acționeze și să apeleze la avocat! Pentru că{" "}
        <b>
          {" "}
          ROLUL AVOCATULUI ESTE, FĂRĂ ÎNDOIALĂ, ACELA DE A APĂRA DREPTURILE
          CETĂȚENILOR ȘI DE A SCOATE LA LUMINĂ ADEVĂRUL ÎN FAȚA JUSTIȚIEI!
        </b>
      </Text>
    </Stack>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Button
          className="btn-back"
          onClick={() => {
            navigate(RoutesOptions.BLOG)
          }}
        >
          <Image src={"./icon-arrow.png"} height={"1rem"} width={"1rem"} />
        </Button>
        <Stack align={"center"} spacing={0} mb="md" mt="2rem">
          <Text
            size={isTablet ? "md" : "xs"}
            ff="Barlow"
            fw={400}
            c="#D3D3D3"
            transform="uppercase"
          >
            {t("blog_title")}
          </Text>
          <Text
            size={"xl"}
            ff="Barlow"
            mb="md"
            fw={600}
            transform="uppercase"
            c="#b99350"
          >
            {t("title_article_11")}
          </Text>
        </Stack>

        {postTextRo}
        <Flex justify="space-evenly" direction={isTablet ? "column" : "row"}>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("published")}:{" "}
            <span style={{ fontStyle: "italic", color: "#b99350" }}>
              {t("date_article_11")}
            </span>{" "}
          </Text>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("source")}:
            <a
              href="https://www.facebook.com/permalink.php?story_fbid=122159124584361414&id=61560842432737&ref=embed_post"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#b99350",
                fontStyle: "italic",
              }}
            >
              <img
                src="/facebook.png"
                width="18px"
                height="18px"
                alt="facebook"
                style={{ marginBottom: "3px", marginLeft: "5px" }}
              ></img>{" "}
              Avocat Camelia Pau
            </a>
          </Text>
        </Flex>
      </MiddleContainer>
      <FooterPage />
    </>
  )
}
